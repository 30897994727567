import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ExchangeWallet } from '@features/exchange-wallet';
import { ContainerRow } from '@src/components/styled';
const WalletsListContainer = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.l,
    flexWrap: 'wrap',
}));
const SupportedWalletsList = ({ wallets, portfolios, isLoading = false, disabled = false, }) => {
    if (isLoading) {
        return _jsxs(WalletsListContainer, { children: [_jsx(ExchangeWallet, { portfolios: portfolios, isSupported: true, isLoading: true, disabled: disabled }, 'wallet-skeleton-1'), _jsx(ExchangeWallet, { portfolios: portfolios, isSupported: true, isLoading: true, disabled: disabled }, 'wallet-skeleton-2'), _jsx(ExchangeWallet, { portfolios: portfolios, isSupported: true, isLoading: true, disabled: disabled }, 'wallet-skeleton-3'), _jsx(ExchangeWallet, { portfolios: portfolios, isSupported: true, isLoading: true, disabled: disabled }, 'wallet-skeleton-4')] });
    }
    return (_jsx(WalletsListContainer, { children: wallets.map((wallet) => (_jsx(ExchangeWallet, { wallet: wallet, portfolios: portfolios, isSupported: true, isLoading: isLoading, disabled: disabled }, wallet.id))) }));
};
export default SupportedWalletsList;
