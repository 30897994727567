import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import EmptyViewInteractive from '@src/components/empty-views/EmptyViewInteractive';
import Icon from '@shared/ui/icons/Icon';
import { ReactComponent as ArrowIcon } from '@icons/wolfkit-light/arrow-left-light.svg';
import { SurfaceColumnWithShadow } from '@src/components/styled';
import { ReactComponent as ExchangesEmptyIcon } from '@icons/custom/exchanges-empty.svg';
import { ConnectExchangeButton } from '@features/exchange/exchange-connect';
const ArrowIconStyled = styled(ArrowIcon)(() => ({
    transform: 'rotate(180deg)',
}));
const EmptyViewContainer = styled(SurfaceColumnWithShadow)(() => ({
    alignItems: 'center',
    justifyContent: 'center',
    padding: '60px 0',
}));
const ConnectExchangeEmptyView = () => {
    const { t } = useTranslation();
    return (_jsx(EmptyViewContainer, { children: _jsx(EmptyViewInteractive, { messageTitle: t('overview.empty_states.connect_exchange.title'), messageBody: t('overview.empty_states.connect_exchange.content'), ActionBtn: (_jsx(ConnectExchangeButton, { variant: 'filled', color: 'primary', size: 'medium', endIcon: ArrowIconStyled, children: t('overview.empty_states.connect_exchange.action_title') })), BodyImgComponent: (_jsx(Icon, { size: 200, IconComponent: ExchangesEmptyIcon, keepOriginalColor: true })) }) }));
};
export default ConnectExchangeEmptyView;
